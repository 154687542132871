@media (min-width: 1280px) {
  .spacingDetail {
    padding-left: 8px;
  }
}

/* *cards Documentos Soporte */

.titleCardSupport {
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
}

@media (max-width: 600px) {
  .titleCardSupport {
    font-size: 16px;
  }
}

.dataSupportGrid {
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
}
.totalSupportGridPaid {
  color: #2cc63e;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}

@media (max-width: 600px) {
  .totalSupportGridPaid {
    font-size: 16px;
  }
}
.totalSupportGridUnpaid {
  color: #f5d13d;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}

@media (max-width: 600px) {
  .totalSupportGridUnpaid {
    font-size: 16px;
  }
}
.totalSupportGridInArrears {
  color: #ec005f;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}

@media (max-width: 600px) {
  .totalSupportGridInArrears {
    font-size: 16px;
  }
}

.totalSupportGridAnnulment {
  color: #e292ff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}

@media (max-width: 600px) {
  .totalSupportGridAnnulment {
    font-size: 16px;
  }
}

.dataNumberSupport {
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.totalSupportGridPaid2 {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.totalSupportGridUnpaid2 {
  color: #f5d13d;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.totalSupportGridInArrears2 {
  color: #ec005f;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}

/* ! Fin cards Documentos Soporte */

.cardDetailSupport {
  height: 98px;
  width: 98%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  margin-bottom: 12px;
  cursor: pointer;
  padding: 1%;
}
.cardDetailSupport:hover {
  border-color: #16b1f3;
  border-style: ridge;
  /*box-shadow: 0 0 7px 0 #16B1F3;*/
}

.cardDetailSupportSelected {
  height: 98px;
  width: 98%;
  border-radius: 4px;
  background-color: #16b1f3;
  margin-bottom: 12px;
  cursor: pointer;
  padding: 1%;
  box-shadow: 0 0 7px 0 #16b1f3;
}

.totalSupportGridSelected {
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
@media (max-width: 600px) {
  .totalSupportGridSelected {
    font-size: 16px;
  }
}

.cardDetailSupportFullData {
  width: 100%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);

  padding: 1%;
}

.detailSupportNumber {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  line-height: 23px;
}

.totalSupportGridPaidDetail {
  color: #16b1f3;
  font-family: Muli;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
  align-items: center;
}
.totalSupportGridPaidState {
  color: #16b1f3;
  font-family: Muli;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: right;
}

.dataSupportGrid {
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.lineDivisionSupport {
  height: 1;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 10px;
}

.constumerNameDetailSupport {
  margin: 10px 0px;
  color: #f6d746;
  font-family: Muli;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  word-break: break-word;
}

.detailSupportInfo {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.greenTitleSupport {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
  line-height: 23px;
  border-radius: 5px;
  height: 36px;
  background-color: rgba(18, 18, 18, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailSupportInfoDetail {
  padding-left: 2px;
}

@media (max-width: 600px) {
  .detailSupportInfoDetail {
    padding-left: 15px;
  }
}

/*  *Componente Articulos */

.titleArticle {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  letter-spacing: -0.2px;
}

@media (max-width: 600px) {
  .titleArticle {
    font-size: 16px;
  }
}

.contentImgDetail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardArticleSupport {
  width: 100%;
  border-radius: 4px;
  background-color: #5e5e5e;
  fill: Solid;
  padding: 2%;
}

.valueinfoCard {
  font-family: Muli;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0;
  margin-bottom: 5px;
}

.unitValueArticle {
  align-items: center;
  color: #2cc63e;
  display: flex;
  font-family: Muli;
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  word-break: break-all;
  line-height: normal;
}

.amountArticle {
  align-items: center;
  color: #fff;
  display: flex;
  font-family: Muli;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  word-break: break-word;
  line-height: normal;
}

.infoIva {
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: #d8d8d8;
}

.infoIva span {
  color: #2cc63e;
}

.totalNumber {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  text-align: right;
  color: #2cc63e;
}

.totalNumberSpan {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.2px;
  color: #ffffff;
  margin-right: 12px;
}

/*  ! Fin Componente Articulos */
/*  *Iconos Footer */

.bottomButtonsSupport {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 72px;
  text-align: center;
  padding-top: 2%;
}

.textButtonsSupport {
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

/* * Footer crear nuevo Soporte */

.bottomButtonsSupportRevision {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  min-height: 64px;
  height: auto;
  text-align: center;
  padding-top: 0.8%;
}
