.backgroudBody {
}

.footerStep {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 99%;
  color: white;
  text-align: center;
  margin: auto;
  padding: 10px !important;
}

/* @media only screen and (max-width: 767px) {
  .conntentStep {
    background-image: url("../../images/wizard/backgrounWizard2.png");
  }
} */


.conntentStep {
  height: 100vh;
  background-repeat: no-repeat;
  background-image: url("../../images/wizard/backgrounWizard2.png");
  background-position: center center;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.omitirStep {
  position: absolute;
    top: 2vw;
    right: 5vw;
    font-style: italic !important;
    cursor: pointer;
    color: #16b1f3;
    font-family: Muli;
    font-size: 5vw;
}

/* .btnBack{
    position: absolute;
    bottom: 15px;
    left: 117px;
    font-style: "italic" !important;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
    font-family: Muli !important;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
} */

.step1 {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imgLogo {
  margin: 0 0 2vw 0;
  width: 11.458vw;;
  padding-top: 2vw;
}
.imgStep1 {
  width: 53vw;
}

.imgStep4 {
  width: 53vw;
}

.textStep1 {
  width: 27vw;
  text-align: center;
  position: absolute;
  bottom: 5vw;
  right: 9vw;
  background-color: rgba(65, 65, 65, 0.95);
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2vw !important;
  font-size: 1.2vw !important;
  padding: 1vw;
  font-style: italic;
}

.textStep2 {
  width: 24vw;
  text-align: center;
  position: absolute;
  bottom: 2vw;
  left: 7vw;
  background-color: rgba(65, 65, 65, 0.95);
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2vw !important;
  font-size: 1.2vw !important;
  padding: 2vw;
  font-style: italic;
}

.textStep3 {
  width: 24vw;
  text-align: center;
  position: absolute;
  bottom: 2vw;
  left: 7vw;
  background-color: rgba(65, 65, 65, 0.95);
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2vw !important;
  font-size: 1.2vw !important;
  padding: 2vw;
  font-style: italic;
}

.contentStep4 {
  width: 54vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imgStep4 {
  width: 36vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    right: 10vw !important;
}

.textStep4 {
  width: 29vw;
  height: 9vw !important;
  padding: 4vw;
  padding-bottom: 3vw;
  text-align: center;
  position: absolute;
  bottom: 9vw;
  right: 7.6vw;
  background-color: #16b1f3;
  color: #ffffff;
  font-family: Muli;
  font-style: italic;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 2vw !important;
  font-size: 1.4vw !important;
  z-index: 1;
  justify-content: center;
}

.MuiMobileStepper-root {
  background-color: rgba(65, 65, 65, 0.95) !important;
}

.linkColfactura {
  color: #16b1f3;
  cursor: pointer;
}

.content-btn{
  bottom: 3vw;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
}

@media (max-width: 1024px) {
  .imgLogo {
    margin: 0 0 7vw 0;
    width: 29vw;
    padding-top: 13vw;
  }

  .omitirStep {
    position: absolute;
    top: 6vw;
    right: 4vw;
    font-style: "italic";
    cursor: pointer;
    color: #16b1f3;
    font-family: Muli;
    font-size: 5vw;
  }

  .footerStep {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    color: white;
    text-align: center;
    margin: auto;
    padding: 3vw !important;
  }


  .step1 {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .imgStep1 {
    width: 100vw;
    height: 66vw;
  }

  .textStep1 {
    width: 89vw;
    padding: 5vw;
    text-align: center;
    position: absolute;
    top: 77vw;
    background-color: rgba(65, 65, 65, 0.95);
    height: 44vw;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 6vw !important;
    font-size: 4.7vw !important;
    font-style: italic;
    left: 0vw;
  }

  .makeStyles-btnNext-2 {
    color: #ffffff !important;
    width: 64.7vw !important;
    height: 7vw !important;
    text-transform: none !important;
    background-color: #16b1f3 !important;
    top: 95vw !important;
    position: absolute;
    font-size: 3vw !important;
  }

  .makeStyles-btnBack-3 {
    left: 15vw !important;
    color: #ffffff !important;
    width: 26.7vw !important;
    /* bottom: 20vw !important; */
    cursor: pointer !important;
    height: 8vw !important;
    display: flex !important;
    position: absolute !important;
    font-size: 2.9vw !important;
    align-items: center !important;
    letter-space: 0 !important;
    justify-content: space-between !important;
    top: 81vw !important;
  }

  .textStep2 {
    width: 90vw;
    padding: 5vw;
    text-align: center;
    position: absolute;
    top: 77vw;
    background-color: rgba(65, 65, 65, 0.95);
    height: 32vw !important;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 6vw !important;
    font-size: 4.7vw !important;
    font-style: italic;
    left: 0vw;
  }

  .textStep3 {
    width: 90vw;
    padding: 5vw;
    text-align: center;
    position: absolute;
    top: 77vw;
    background-color: rgba(65, 65, 65, 0.95);
    height: 38vw !important;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 6vw !important;
    font-size: 4.7vw !important;
    font-style: italic;
    left: 0vw;
  }

  .contentStep4 {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .imgStep4 {
    width: 82vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    right: 0vw;
  }

  .textStep4 {
    width: 90vw;
    height: 35vw !important;
    padding: 5vw;
    padding-bottom: 4vw;
    text-align: center;
    position: absolute;
    top: 80vw;
    left: 0vw;
    background-color: #16b1f3;
    color: #ffffff;
    font-family: Muli;
    font-style: italic;
    font-weight: 500 !important;
    letter-spacing: 0;
    line-height: 8vw !important;
    font-size: 5vw !important;
    z-index: 1;
    justify-content: center;
  } 

  .content-btn{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
